import React from 'react';
import styled from '@emotion/styled';
import {ImgPath} from '../../config';

const OrthogonalContainer = styled.article`

    #pears{
        width:190px;
        height:125px;
    }
    #resur{
        width: 173px;
        height: 192px;
    }
    #fantin{
        width: 173px;
        height: 192px;
   }
    #peonies{
        width: 150px;
        height: 163px; 
    }
    
`;

const Path = `${ImgPath}/orthogonal/`;

function Orthogonal() {

    return (
        <OrthogonalContainer>
            <p>
            <img id="pears" className="right" src={`${Path}pears_basket_thumb.jpg`} alt="Pear Basket" />
                I've been thinking about the picture plane for the past couple of years. In many of my still lifes, the tabletop is parallel to the edge of the painting (or the frame). This means that as a viewer you are observing the setup straight-on, or orthogonally.
            </p>

            <p>
                There is nothing wrong with this view; Piero della Francesca put it to great use in his <span className="italic">Resurrection</span>. 
                Note that Francesca's and my paintings are not only orthogonal from right to left, but their eye-level view of the objects creates an vertical orthogonality as well. In general, an orthogonal view gives a painting a powerful and arresting composition. Our paintings also share a triangular composition which gives them stability.
                <img id="resur" className="left" src={`${Path}resurrex.jpg`} alt="Ressurection"  /> 
            </p>

            <p>
                Two summers ago, I was in Lausanne, Switzerland and was lucky enough to catch a retrospective of Henri Fantin-Latour at the Fondation de l'Hermitage--130 works filling three floors. (This is a rare treat; although every museum owns a couple Fantin-Latour florals, you <span className="italic">never</span> get to see a whole bunch of his paintings in one place.) 
                <img id="fantin"className="left" src={`${Path}fantin-latour.jpg`} alt="Fantin Latour"  />
                I toured the show with picture-plane in mind, and noted that only one of his still lifes used the orthogonal view--the painting was a gift from him to his fiancée. Isn't this a jarring setup for a Fantin-Latour? It really looks strange to me, the objects almost look symbolic. Every other painting in the show had the setup at an angle, like the peonies below.
                <img id="peonies" className="right" src={`${Path}whitepeonies.jpg`} alt="White Peonies"  />     

            </p>

            <p>
                One year later, while visiting San Francisco, I found myself on Post St., yep, right in front of The John Pence Gallery. So why not go in? In my picture-plane frame of mind, I counted how many still lifes in the gallery had the setup sitting skewed to the frame: zero. At the risk of sounding like Joe Biden, let me repeat that: <span className="bold italic">Not a single still life in all three salons of the main gallery, nor in the storage gallery next door which you have to know about and request to go into, had the setup at an angle to the frame.</span> What do you make of <span className="italic">that</span>? (1/14/2009)
            </p>
        </OrthogonalContainer>
    );
}

export default Orthogonal;
