import React, {useState} from 'react';
import styled from '@emotion/styled';
import Main from './Main';
import {ApiPath} from '../config';

const ContactContainer = styled.div`

    h3{
        text-align: center;
        font-weight: bold;
    }
    .error{
        color: red;
    }
    form{
        margin: 1rem 2rem;
        label{
            display: block;
            float: left;
            clear: left;
            margin: .5rem;
        }

        input{
            margin-right: 1rem;
            float: right;
            clear: right;
            width: 80%;
        }
        textarea{
            margin-right: 1rem;
            float: right;
            clear: right;
            width: 80%;
            height: 8rem;
        }
        button{
            margin: 1rem;
            float: right;
            clear: both;
        }
    }

    @supports (display: grid){
        form{
            margin: 1rem 3rem;
            display: grid;
            grid-template-columns: 1fr 8fr;
            grid-template-rows: auto auto auto auto;
            align-items: center;
            row-gap: .25rem;
            label{
                display: block;
                float: none;
                clear: none;
                grid-column: 1/2;
            }

            input{
                float: none;
                clear: none;
                width: 100%;
                height: 100%;
                grid-column: 2/3;
                box-sizing: border-box;
            }
            textarea{
                margin: 0;
                float: none;
                clear: none;
                width: 100%;
                grid-column: 2/3;
                height: 8rem;
                box-sizing: border-box;

            }
            button{
                float: none;
                clear: none;
                justify-self: end;
                grid-column: 1/3;
                margin-right: 0;
            }
        }
    }
    @media screen and (max-width: 768px){

    }
    
`;


function Contact() {

    const [error, setError] = useState(false)
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")

    function handleSubmit(e){
        e.preventDefault()
        console.log("submitting",email,subject,body);

        async function postData(url = '', data = {}) {
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            });
            return response.status;
        }

        postData(`${ApiPath}/mail`, { from: email, subject, body })
        .then(data => {
            console.log(data); // JSON data parsed by `data.json()` call

            //TODO: toast to show sent email
            if (data === 200){
                setEmail("");
                setSubject("");
                setBody("");  
                setError(false);
  
            } else {
                setError(true);
                console.log(data.message)
            }
            
        });
    }
    return (
        <Main>
            <ContactContainer>
                <h3>Contact Me</h3>
                <form onSubmit={handleSubmit}>
                    <label>
                        Email:
                        </label>
                        <input
                            name="Email"            
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)} />
                    
                    <label>
                        Subject:
                        </label>
                        <input
                            name="Subject"            
                            type="text"
                            value={subject}
                            onChange={e => setSubject(e.target.value)} />

                    <label>
                        Body:
                        </label>
                        <textarea
                            name="Body"            
                            type="paragraph"
                            value={body}
                            onChange={e => setBody(e.target.value)} />
                    <button type="submit">Send</button>
                </form>
                {error && <span className="error">There was an error with submitting, please refresh and try again</span>}
            </ContactContainer>
        </Main>
    );
}

export default Contact;
