import React from 'react';
import styled from '@emotion/styled';
import Main from './Main';
import {ImgPath} from '../config';


//comes from Blogs
const AboutContainer = styled.div`
    margin-top: 2rem;
    .italic{
        font-style: italic;
    }
    .bold{
        font-weight: bold;
    }
    .right{
        float: right;
        margin-right: 1rem;
        clear: right;
    }
    .left{
        float: left;
        clear: left;
    }
    img{
        margin: 0.5rem;
    }
    h3{
        clear: both;
        margin: 1.5rem auto;
        text-align: center;
        font-weight: bold;
    }
    p{

    }

    #mainimg{
        max-width: 500px;
        max-height: 332px;
        height: auto;
        width: 60%;
        margin-top: 0.25rem;
    }
    @media screen and (max-width: 768px){
        margin: 2rem;
    }
`;

function About() {

    return (
        <Main>
            <AboutContainer>
                <h3>About Me</h3>
                <img id="mainimg" className="left" src={`${ImgPath}/main/lisa4-ORCity.jpg`} alt="Lisa Caballero --  Oregon City"/>
                <p>
                    I'm an unabashed observer.
                </p>
                <p> 
                    What I mean by that is that I paint what I see, and it is in front of me when I paint it. I look at things for a long, long time, hours, days, weeks.
                </p>
                <p>
                    Among painters, there are observers and there are inventors, and there are a very few special artists who move with ease between the two, like Velazquez, Titian and Rembrandt.
                </p>
                <p>
                    To give you an example of what I'm talking about: Michaelangelo <span className="italic">invented </span> the ceiling of the Sistine Chapel, Chardin <span className="italic"> observed </span> his still lifes. God and Adam did <span className="italic bold">>not</span> pose for Michaelangelo, but that apple in the Chardin once existed, and Chardin probably ate it when he was done with the painting. For me, the intimacy between Chardin and his apple is electric.
                </p>
                <p>
                    This healthy tension between observation and invention runs through the history of European art. Of the two stances, however, observation has tended to get less respect. Historically, you can see this bias in the Academic "Hierarchies of Painting Genres" that placed the (by necessity) invented History and Religious paintings at the top, and the lowly (but closely observed) Still Life at the bottom, with Landscape, Portraiture and "Genre" painting in various orders in between.
                </p>

                <p>
                    Right now we live in a curious moment. People are obsessed with how their brains work but at the same time have probably never used them so poorly. We discount our mind to our devices. Against that backdrop, picking up a brush and sustaining uninterrupted, quiet looking is an act of rebellion.
                </p>
                
                <p>
                    To me, Realism is about attending to a subject. It bears witness to the ordinary and connects you to everyone who ever noticed what you notice, and to the artist who brushstroke by brushstroke thought it important to describe.  Description is difficult and involves a lot of artifice, vision is messy, the world is infinitely complex. To be true to our experience, Realism should embrace both confusion and ambiguity, it is more realistic when it does.
                </p>
            </AboutContainer>
        </Main>
    );
}

export default About;
