import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';

const MainMainContainer = styled.main`
    background-color: var(--color-background);
    display: inline-block;
    width: 72vw;
    height: 100vh;
    box-sizing: border-box;
    
    overflow-y: scroll;

    /* margin-top: 2rem; */
    /* margin-bottom: 2rem; */
    /* height: 90%; */
    @media screen and (max-width: 768px){
        width: 100vw;
        /* height: 100vh; */
        padding-bottom: 10rem;
        margin-top: ${props => (props.sizediff > 100) ? `${props.sizediff/3}px`: "4rem"};
    }
`;

function Main(props) {

    const [height, setHeight] = useState(window.innerHeight)
    const sizediff = window.outerHeight - height;
    console.log(sizediff)

    useEffect(() => {
        function setMeasuredHeight() {
          const measuredHeight = document.documentElement?.clientHeight || window.innerHeight
          setHeight(measuredHeight)
        }
    
        window.addEventListener('resize', setMeasuredHeight)
        return () => window.removeEventListener('resize', setMeasuredHeight)
      }, [])
    
    return (
        <MainMainContainer {...{sizediff:sizediff}}>
                {props.children}
        </MainMainContainer>
    );
}

export default Main;
