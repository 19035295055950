import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {ImgPath} from '../config';
import Sig from '../assets/sig.svg'
import {isMobile} from 'react-device-detect';

const HomeMainContainer = styled.main`
    background-color: var(--color-background);
    margin: 0 auto;

    .mainimg{
        display: inline-block;
        max-width: 100%;
        max-height: 17vh;
        height: auto;
        width: auto;
        margin: auto;
    }
    .link-container{
        display: block;
        margin: 1rem auto;
        a{
            text-decoration: none;
            margin: 0 2rem 1rem 2rem;
            h2{
                text-align: center;
                margin-top: .5rem;
                align-self: end;
            }
        }
        .paintings{
            display: block;
        }
        img{
            max-width: 70vw;
            min-height: 45vh;
            height: auto;
            width: auto;
        }
    }
    @media screen and (max-width: 768px){
        padding-bottom: 8rem;

        .mainimg{
            margin: 2rem auto;

        }
    }

    @supports (display: grid){
        display: grid;
        width: 100vw;
        height: 100vh;
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: minmax(2rem,0.5fr) minmax(auto, 1.5fr) minmax(auto, 2fr) minmax(4rem,1fr);
        grid-template-areas:
        ". . ."
        ". sig ."
        ". links ."
        ". . .";
        .mainimg{
            grid-area: sig;
        }
        .link-container{
            grid-area: links;
            width: 70vw;
            a{
                margin: 0;
            }
            img{
                max-width: 70vw;
                max-height: 60vh;
                height: auto;
                width: auto;
                display: block;
                margin: auto auto;
            }
        }

        @media screen and (max-width: 768px){
            .mainimg{
                max-height: 30vh;
                height: auto;
                width: auto;
                display: block;
                margin: auto auto;
            }
            .link-container{
                margin-bottom: 8rem;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 1fr;
                img{
                    max-width: 65vw;
                    max-height: 45vh;
                    height: auto;
                    width: auto;
                    display: block;
                    margin: auto auto;
                }
                .paintings{
                    justify-self: center;
                }
            }
        }
    }
`;


function Home() {

    const img = isMobile ? `${ImgPath}/10-11_jpegs/dog_biscuits.jpg` : `${ImgPath}/10-11_jpegs/Oregon_City_Paper_Mill.jpg`;

    return (
        <HomeMainContainer>
            <img className="mainimg" src={Sig} alt="Lisa Caballero"/>
            <div className="link-container">
                <Link to="/paintings/14_jpegs" className="paintings">
                    <img src={img} alt="Paintings"/>
                </Link>
            </div>
        </HomeMainContainer>
    );
}

export default Home;
