import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { Global, css } from '@emotion/react';
import {isMobileSafari} from 'react-device-detect';

import App from './App';

const globalStyles = css`
      
  ::-webkit-scrollbar {
    background-color:transparent;
    width:8px
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color:transparent
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:4px;
    border:2px solid transparent
  }
  ::-webkit-scrollbar-corner {display: none}

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {display:none}

  body {
    /* @import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
    font-family: 'Titillium Web', sans-serif; */

    margin: 0;
    overflow: hidden;
    --color-link : #444444;
    --color-link-hover : #eeeeee;
    --color-white : #ffffff;
    --color-light-gray : #43474c;
    --color-gray : #2c2f33;
    --color-dark-gray : #23272a;
    --color-dark-gray-transparent : rgba(20, 21, 21, 0.93);
    --color-text : #330000;
    --color-light-text : #b9b9b9;
    --color-background : #bbbbbb;
    --color-light-background : #dddddd;

    color: var(--color-text);
    background-color: var(--color-background);
    scrollbar-width: none;
    -ms-overflow-style: none;

    a{
      color: var(--color-text);
      &:hover{
        color: var(--color-link-hover);
      }
      &.active{
        color: var(--color-link-hover);
      }
    }
    h1{
      font-weight: 200;
    }
    h2{
      font-weight: 600;
      font-size: 1.2rem;
    }
    h3{
      font-weight: 200;
    }
    h4{
      font-weight: 200;
    }
    .italic{
        font-style: italic;
    }
    .bold{
        font-weight: bold;
    }
    .right{
        float: right;
        margin-right: 1rem;
        clear: right;
    }
    .left{
        float: left;
        clear: left;
    }
    .centerimg{
        display: block;
        margin: .5rem auto;
    }
    button{
      &:focus{
        outline: none;
      }
    }
    input, textarea{
      border: 1px solid var(--color-text);
      background-color: var(--color-light-background);
      border-radius: 4px;
      height: 1.5rem;
    }
    button{
      border: none;
      padding: .75rem 1rem;
      background-color: var(--color-light-background);
      border-radius: 4px;
      font-size: small;
      &:hover{
        background-color: var(--color-link-hover);
      }
      &:active{
        background-color: var(--color-light-background);
      }
    }
  }
`;

const safariStyles = css`
  #root{
      overflow: hidden;
      position: fixed;
    }
`;


ReactDOM.render(
  <React.StrictMode>
      <Global styles={css`${globalStyles}${isMobileSafari ? safariStyles : ''}`}/>
      <BrowserRouter>
          <App />
      </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);

