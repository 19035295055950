import React from 'react';
import styled from '@emotion/styled';
import {FaSpinner} from 'react-icons/fa';

const SpinContainer = styled.div`
    .spinner{
        animation: spin-animation 1s infinite;
        display: inline-block;
        height: 3rem;
        width: 3rem;
        align-self: center;
        justify-self: center;
    }

    @keyframes spin-animation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }
`;


function Spinner(props) {

    return (
        <SpinContainer>
            <FaSpinner className="spinner"/>
        </SpinContainer>
    );
}

export default Spinner;
