import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import Main from './Main';
import {showMapping} from '../App.js';
import {useParams} from 'react-router-dom';
import {ApiPath} from '../config';


const ShowsContainer = styled.main`
    margin: 0 2rem;
`;

function AddExtraProps(Component, extraProps) {
    return <Component.type {...Component.props} {...extraProps} />;
}

function Shows() {
    let { showid } = useParams();
    const [collection, setCollection] = useState({})

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        let ignore = false;

        async function fetchSearchResults() {
            let responseBody = {};
            setLoading(true);

            try {
                const response = await fetch(
                    `${ApiPath}/shows/${showid}`,
                { 
                    signal: controller.signal,
                }
                );
                responseBody = await response.json();
            } catch (e) {
                if (e instanceof DOMException) {
                    ignore = true;
                    console.log("== HTTP request aborted");
                } else {
                    setError(true);
                    console.log(e);
                }
            }
            if (!ignore) {
                // console.log(responseBody);
                setCollection(responseBody.collections);
                setLoading(false);
                setError(false);
        }
    }
    fetchSearchResults();
        return () => {
            controller.abort();
            ignore = true;
        };
    }, [showid]);

    return (
        <Main>
            <ShowsContainer>
                {showid && (showMapping[showid] ? 
                    AddExtraProps(showMapping[showid].component, {collection: collection, loading: loading, error: error} ) :
                    <p>Entry not found</p> )}
            </ShowsContainer>
        </Main>
    );
}

export default Shows;
