import React, {useState} from 'react';
import styled from '@emotion/styled';
import {FaTimes, FaChevronRight, FaChevronLeft} from 'react-icons/fa';


const GalleryContainer = styled.div`
    margin: 4rem 2rem;

    > ul{
        padding: 0;
        margin: 0;
        li{
            list-style: none;
            display: inline-block;
            margin: 1rem;
            img{
                &:hover{
                    cursor: pointer;
                }
            }
            > span{
                font-weight:900;
                float:right;
                margin: -.5rem .5rem 0 0;
            }
        }
    }

    .fullOverlay{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 10;

        background-color: var(--color-dark-gray-transparent);

        img{
            position: fixed;
            top: 10vh;
            left: 5vw;
            max-width: 60vw;
            max-height: 80vh;
            height: auto;
            width: auto;
            border: 24px solid black;
            border-radius: 8px;
        }
        .card{
            position: fixed;
            bottom: 6rem;
            right: 3rem;
            min-height: 10rem;
            height: auto;
            min-width: 15rem;
            width: 30vw;
            background-color: var(--color-dark-gray);
            border-radius: 4px;
            color: var(--color-light-text);
            h1{
                font-size: 1.5rem;
                margin: .5rem .5rem;
                font-weight: 400;
            }
            h2{
                font-weight: 100;
                margin: .25rem .5rem;
                display: block;
                font-size: 1rem;
                font-style: italic;
            }
            p{
                margin: .5rem;
                font-size: .8rem;
            }
        }
        button {
            height: 2.25rem;
            width: 2.25rem;
            border-radius: 4rem;
            padding: 0;
            border: none;
            background-color: var(--color-dark-gray);
            svg{
                height: 1rem;
                width: 1rem;
                color: var(--color-light-text);

            }
            &:hover{
                cursor: pointer;
            }
        }
        .close{
            position: fixed;
            top: 1rem;
            right: 1.5rem;
        }
        .arrows{
            position: fixed;
            bottom: 3rem;
            right: 3rem;
            min-width: 15rem;
            width: 30vw;
            .prev{

            }
            .next{
                float:right;
            }
        }
    }

    @supports (display: grid){
        margin: 1rem;

        > ul{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            grid-auto-flow: dense;
            gap: 1rem;

            li{
                margin: auto;
                img{
                    max-width: 100%;
                    /* height: 100%; */
                    max-height: 100%;
                    object-fit: cover;
                }
                &.tall{
                    grid-row: span 2;
                }
                &.wide{
                    grid-column: span 2;
                }
                &.supertall{
                    grid-row: span 3;
                }
                &.superwide{
                    grid-column: span 3;
                }
                &.big{
                    grid-row: span 2;
                    grid-column: span 2;

                }
            }
        }
        .fullOverlay{
            display: grid;
            grid-template-columns: 2rem 7fr 2rem minmax(15rem, 3fr) 2rem;
            grid-template-rows: 3fr 4fr;
            img{
                grid-column: 2/3;
                grid-row:1/3;
                position: relative;
                display: block;
                margin: auto;
                top: 0;
                left: 0;
            }
            .card{
                position: relative;
                grid-column: 4/5;
                grid-row: 2/3;
                min-height: 10rem;
                min-width: 0;
                height: auto;
                width: 100%;

                bottom: 0;
                right: 0;

                /* align-self: end; */
                margin-bottom: auto;
            }
            .arrows{
                position: relative;
                grid-row: 1/2;
                grid-column: 4/5;
                bottom: 0;
                right: 0;
                align-self: end;
                min-width: 0;
                height: auto;
                width: 100%;
                margin-bottom: 1rem;
            }
        }
        @media screen and (max-width: 768px){
            .fullOverlay{
                grid-template-columns: auto;
                grid-template-rows: minmax(1rem,1fr) 50vh 2rem auto 30vh minmax(4.5rem,1fr);
                img{
                    grid-column: 1/2;
                    grid-row: 2/3;
                    max-width: 80vw;
                    max-height: 45vh;
                }
                .card{
                    grid-column: 1/2;
                    grid-row: 5/6;
                    width: 80vw;
                    align-self: center;
                    justify-self: center;

                }
                .arrows{
                    justify-self: center;
                    align-self: center;
                    grid-column:1/2;
                    width: 80vw;
                    grid-row: 4/5;
                }
            }
        }
    }
`;


function PaintingGallery(props) {


    
    const [fullOpen, setFullOpen] = useState(false);
    const [openPainting, setOpenPainting] = useState(-1);


    

    const prevPainting = () => {
        setOpenPainting((openPainting - 1) < 0 ? 
                        props.collection.paintings.length -1 : 
                        openPainting - 1)
    }
    const nextPainting = () => {
        setOpenPainting((openPainting + 1) >= props.collection.paintings.length ? 
                        0 : 
                        openPainting + 1)
    }

    return (
        <GalleryContainer>
            {(fullOpen) &&
            <div className="fullOverlay" onClick={(e)=>{if(e.target === e.currentTarget){setFullOpen(false);setOpenPainting(-1);}}}>
                    <img src={props.collection.paintings[openPainting].url} 
                            alt={props.collection.paintings[openPainting].title}
                                />
                    <div className="arrows">
                        <button className="prev" onClick={prevPainting}><FaChevronLeft/> </button>
                        <button className="next" onClick={nextPainting}><FaChevronRight/> </button>
                    </div>
                    <div className="card">
                        <h1>{props.collection.paintings[openPainting].title}</h1>
                        <h2>{`${props.collection.paintings[openPainting].dimensions}, ${props.collection.paintings[openPainting].medium}, ${props.collection.paintings[openPainting].date}`}</h2>
                        {!props.nosale && <h2>{props.collection.paintings[openPainting].sold ? 'sold' : props.collection.paintings[openPainting].cost}</h2>}
                        
                        <p>{props.collection.paintings[openPainting].description}</p>
                    </div>
                    <button className="close" onClick={(e)=>{setFullOpen(false);setOpenPainting(-1);}}><FaTimes/> </button>
            </div>}
            
            <ul>
            {(props.collection && props.collection.paintings) && props.collection.paintings.map((painting, idx) => (
                <li key={painting.title} className={painting.class}>
                    <img src={painting.url} 
                            alt={painting.title}
                            onClick={() => {setOpenPainting(idx); setFullOpen(true);}}
                            />
                    {!painting.sold && <span>.</span>}
                </li>
            ))}
            </ul>
        
        </GalleryContainer>
    );
}

export default PaintingGallery;
