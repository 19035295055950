import React, {useState} from 'react';
import styled from '@emotion/styled';
import Main from './Main';
// import School from './blogs/School';
import Orthogonal from './blogs/Orthogonal';
import {useParams, Link} from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import {ImgPath} from '../config';


const BlogsContainer = styled.div`
    margin: 2rem;

    img{
        margin: 1.5rem;
    }
    h3{
        clear: both;
        margin: 1.5rem auto;
        text-align: center;
        font-weight: bold;
    }
    .back{
        position: fixed;
        top: 2rem;
        left: 27vw;
        svg{
                height: 1.5rem;
                width: 1.5rem;
            }
    } 
    .headers{
        h3{
            margin-bottom: 0;
        }
    }

    > ul{
        width: 100%;
        padding: 0;
        margin: 0;
        li{
            margin: 1rem 0;
            list-style: none;
            padding: 1rem;
            border-radius: 8px;
            background-color: var(--color-light-background);
            .readmore{
                font-weight: bold;
                font-size: small;
            }
        }
    }
    #search{
        width: 100%;
        margin: .5rem auto 0 auto;
        display: block;
        padding: .5rem 0 .5rem .5rem;
        box-sizing: border-box;
        height: 2rem;
    }
    @supports (display: grid){
        > ul {
            li {
                padding: 0;
                display: grid;
                grid-template-columns: 1rem auto auto 1rem;
                grid-template-rows: 1rem auto auto auto 1rem;
                grid-template-areas:
                ". . . ."
                ". title image ."
                ". blurb image ."
                ". link image ."
                ". . . .";
                
                h2{
                    grid-area: title;
                    margin: 0 0 1rem 0;
                }
                img{
                    margin: .5rem;
                    grid-area: image;
                    justify-self: center;
                    align-self: center;
                }
                p{
                    grid-area: blurb;
                }
                .readmore{
                    grid-area: link;
                }
            }
        }
    }
    @media screen and (max-width: 768px){
        margin: 2rem;
        .back{
            top: 4rem;
            left: 0.75rem;
        }
        @supports (display: grid){
            > ul {
                li {
                    grid-template-columns: 1rem auto auto 1rem;
                    grid-template-rows: 1rem auto auto auto auto 1rem;
                    grid-template-areas:
                    ". . . ."
                    ". title title ."
                    ". image image ."
                    ". blurb blurb ."
                    ". link . ."
                    ". . . .";
                }
            }
        }
    }
`;
const mapping = {
    // "school": {
    //     "title": "School",
    //     "path": "/school",
    //     "thumb": "",
    //     "blurb": "",
    //     "component": <School /> 
    // },
    "orthogonal": {
        "title": "The orthogonal picture plane: powerful device, or straightjacket?",
        "path": "/orthogonal",
        "thumb": "/pears_basket_thumb.jpg",
        "blurb": "I've been thinking about the picture plane for the past couple of years. In many of my still lifes, the tabletop is parallel to the edge of the painting (or the frame). This means that as a viewer you are observing the setup straight-on, or orthogonally.",
        "component": <Orthogonal />
    }
    
}

function Blogs() {
    let { blogid } = useParams();
    const [search, setSearch] = useState("");

    return (
        <Main>
            <BlogsContainer>
                <div className="headers">
                    <h3>{mapping[blogid] ? mapping[blogid].title : "Blogs"}</h3>
                    {!blogid && <input id="search" placeholder="search" value={search} onChange={(e) => {setSearch(e.target.value)}}/>}
                </div>
                {!blogid && 
                <ul>
                    {Object.entries(mapping).map(([key, val]) => 
                        (search==="" || val.title.toUpperCase().includes(search.toUpperCase()) || val.blurb.toUpperCase().includes(search.toUpperCase())) && 
                        <li key={key}>
                            <h2><Link to={`/blog${val.path}`}>{val.title}</Link></h2>
                            {val.thumb && <img src={`${ImgPath}${val.path}${val.thumb}`} alt={val.title}/>}
                            <p>{val.blurb}</p>
                            <Link className="readmore" to={`/blog${val.path}`}>Read More...</Link>
                        </li>
                    )}
                </ul>}
                {blogid && <Link to="/blog" className="back"><FaArrowLeft/></Link>}
                {blogid && (mapping[blogid] ? mapping[blogid].component : <p>Entry not found</p> )}
            </BlogsContainer>
        </Main>
    );
}

export default Blogs;
