import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import Main from './Main';
import PaintingGallery from './PaintingGallery';
import Spinner from './Spinner.js';
import {useParams} from 'react-router-dom';
import {ApiPath} from '../config';


const PaintingsMainContainer = styled.div`
    margin-top: 16.5vh;
    margin-right: 1.5rem;

    .loadingContainer{
        margin-left: 30vw;
    }
    @media screen and (max-width: 768px){
        margin-top: 0;
        margin-right: 0;
    }
`;


function Paintings(props) {

    let { collectionid } = useParams();
    const [collection, setCollection] = useState({})

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        let ignore = false;

        async function fetchSearchResults() {
            let responseBody = {};
            setLoading(true);

            try {
                const response = await fetch(
                    `${ApiPath}/collections/${collectionid}`,
                { 
                    signal: controller.signal,
                }
                );
                responseBody = await response.json();
            } catch (e) {
                if (e instanceof DOMException) {
                    ignore = true;
                    console.log("== HTTP request aborted");
                } else {
                    setError(true);
                    console.log(e);
                }
            }
            if (!ignore) {
                // console.log(responseBody);
                setCollection(responseBody.collections);
                setLoading(false);
                setError(false);
        }
    }
    fetchSearchResults();
        return () => {
            controller.abort();
            ignore = true;
        };
    }, [collectionid]);
    
    return (
        <Main>
            <PaintingsMainContainer>
                {error && <p className="error">An error has occured, refresh the page</p>}
                {!error && (loading ? <div className="loadingContainer"><Spinner/></div> :
                        <PaintingGallery collection={collection}/>
                )}
            </PaintingsMainContainer>
        </Main>
    );
}

export default Paintings;
