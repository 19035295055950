import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import NavLinkAccordion from './NavlinkAccordion.js'
import { FaBars, FaTimes } from 'react-icons/fa';

const Nav = styled.div`
    background-color: var(--color-background);
    display: inline-block;
    height: 100vh;
    width: 28vw;
    > ul{
        display: block;
        /* border: 1px solid red; */
        border-radius: 16px;
        margin: 15vh auto 10vh auto;
        padding: 2rem 0;
        font-size: large;
        li{
            list-style: none;
            padding-top: .25rem;
            padding-bottom: .25rem;
            text-align: center;
            a{
                display: inline-block;
                width: 100%;
                text-decoration: none;
                margin: 0 auto;
            }
        }
    }
    > button {
        display: none;
    }
    

    @media screen and (max-width: 768px){
        width: 100vw;
        /* opacity: 0.5; */
        background-color: var(--color-dark-gray-transparent);
        &.closed {
            max-width: 1px;
            /* background-color: red; */
            ul{
                display: none;
            }
        }
        &.open{
            /* left: 10vw;
            right: 10vw; */
            top: 0;
            position: absolute;
            /* opacity: 0.8; */
            ul{
                width: 80vw;
                background-color: var(--color-background);
            }
        }

        > button {
            display: block;
            position: fixed;
            top: 2vh;
            right: 2vh;
            border: none;
            background-color: transparent;
            z-index: 10;
            padding: 0;

            svg{
                height: 4vh;
                width: 4vh;
            }

            &:hover{
                background-color: transparent;
                cursor: pointer;
            }
            &:active{
                background-color: transparent;
            }
        }

    }
    
`;


function Navbar(props) {
    const [open, setOpen] = useState(false);
    

    function toggleOpen(){
        setOpen(!open);
    }
    function closeBar(){
        setOpen(false);
    }

    

    return (
        <Nav 
            className={`${open ? 'open' : 'closed'}`} 
            onClick={(e)=>{if(e.target === e.currentTarget){closeBar()}}}
            >
            <button onClick={toggleOpen}>{open ? <FaTimes/> : <FaBars/>}</button>
            <ul>
                <li onClick={closeBar}><NavLink exact to="/">Home</NavLink></li>
                <NavLinkAccordion 
                    content={props.Collections}
                    close={closeBar} 
                    path="/paintings"
                    text="Paintings"/>
                <NavLinkAccordion 
                    content={props.Shows}
                    close={closeBar}
                    path="/shows" 
                    text="Shows"/> 
                <li onClick={closeBar}><NavLink to="/blog" >Blog</NavLink></li>
                <li onClick={closeBar}><NavLink to="/about" >About Me</NavLink></li>
                <li onClick={closeBar}><NavLink to="/contact" >Contact</NavLink></li>
            </ul>
        </Nav>
    );
}

export default Navbar;
