import styled from '@emotion/styled';
import {useEffect, useState } from 'react';
import { Switch, Route, Link, useLocation} from 'react-router-dom';
import Navbar from './components/Navbar.js';
import Paintings from './components/Paintings.js';
import Blogs from './components/Blogs.js';
import Shows from './components/Shows.js';
import Home from './components/Home.js';
import About from './components/About.js';
import Contact from './components/Contact.js';
import OHS from "./components/shows/OHS.js"
import fetch from 'isomorphic-unfetch';
import {ApiPath} from './config';
import Sig from './assets/sig2.svg';

import './App.css';

const Body = styled.div`
  background-color: var(--color-background);
  display: flex;

  .home{
    position: fixed;
    top: .5rem;
    left: 3vw;
    img{
      width: 22vw;
      max-height: 3.25rem;
    }
  }
  
  @supports (display: grid){
    display: grid;
    grid-template-columns: auto auto;
  }
  @media screen and (max-width: 768px){
    .home{
      img{
        width: 25vw;
      }
    }
  }
  
  /* nav{
    grid-column: 1/2;
    grid-row: 1/2;
  }
  > div{
    grid-row: 2/3;
    grid-column: 1/2;
    overflow-y: scroll;
    padding-bottom: 8rem;
  } */
`;

export const routes = [
  {id: 0, path: '/paintings/:collectionid', component: Paintings},
  {id: 1, path: '/blog/:blogid', component: Blogs},
  {id: 2, path: '/blog', component: Blogs},
  {id: 3, path: '/shows/:showid', component: Shows},
  {id: 4, path: '/about', component: About},
  {id: 5, path: '/contact', component: Contact}
]

export const showMapping = {
  "OHS" : {
      "title": "Oregon Historical Society",
      "path": "/OHS",
      "component": <OHS/>  
  }
  // ,
  // "university_club" : {
  //     "title": "University Club",
  //     "path": "/university_club",
  //     "component": <OHS/>
  // }
}


function App() {
  const location = useLocation();
  const [Collections, setCollections] = useState({});
  // const [Blogs, setBlogs] = useState({});
  // const [Shows, setShows] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    let ignore = false;

    async function fetchSearchResults() {
        let responseBody = {};

        try {
            const response = await fetch(
                ApiPath + `/nav`,
            { 
                signal: controller.signal,
            }
            );
            responseBody = await response.json();
        } catch (e) {
            if (e instanceof DOMException) {
                ignore = true;
                console.log("== HTTP request aborted");
            } else {
                // setError(true);
                console.log(e);
            }
        }
        if (!ignore) {
            // console.log(responseBody);
            setCollections(responseBody.collections);
    }
}
fetchSearchResults();
    return () => {
        controller.abort();
        ignore = true;
    };
}, []);


  return (
    <Body>
      {location.pathname !== '/' &&
        <Navbar Collections={Collections} Shows={showMapping}/>
      }
      {location.pathname !== '/' &&
        <Link className="home" to="/"><img  src={Sig} alt="Lisa Caballero"/></Link>
      }

      <Switch>
        {routes.map(route => (
          <Route key={`${route.id}`} path={route.path} component={route.component} /> 
        ))}
        <Route exact path="/" component={Home} />
        <Route path="/" ><div>404</div></Route>
      </Switch>

    </Body>
  );
}

export default App;
