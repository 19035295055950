import React from 'react';
import styled from '@emotion/styled';
import {ImgPath} from '../../config';
import PaintingGallery from '../PaintingGallery.js'
import Spinner from '../Spinner.js';

const OHSContainer = styled.article`

    #entrance{
        max-height:421px;
        min-height: 10rem;
        max-width: 600px;
        min-width: 15rem;
        width: 66%;
        height: auto;
    }
    .loadingContainer{
        margin: 5rem 0 0 30vw;
    }
`;

const path = `${ImgPath}/OHS/`;


function OHS(props) {

    
    return (
        <OHSContainer>
            
            <p>
                The <span className="bold">History as Muse, Art as Record</span> exhibit runs from September 18 to December 30, 2012 at The Oregon Historical Society. The exhibit is the culmination of my interest in the historic Dairy Cooperative Association (DCA) buildings in Southeast Portland. Over the course of two years I set up my easel on SE 8th Street and painted. As I learned more of the Coop's history, the history itself became as inspiring to me as the evocative buildings. 
            </p>
            <img id="entrance" className="centerimg" src={`${path}OHS-entrance1.jpg`} alt="Oregon Historical Society" width="600" height="421" />

            <p>
                The exhibit is aptly named. History was indeed a muse, and, in that half of the structures were recently demolished, these paintings are among the last records of the feed mill and silos. Change in the neighborhood is being driven by the new MAX light rail line which will pass through it. TriMet gave me an aerial map of the neighborhood, with their mechanical drawing of the rail line superimposed. To that, I added red dots and sight lines to indicate where I stood and looked. (See below.)
            </p>

            <p>
                The exhibit features my paintings paired with one of my sources of inspiration, Mark Moore's collection of Mayflower Farms and DCA dairy memorabilia. Mark displays his collection online at <a rel="noreferrer" target="_blank" href="http://www.pdxhistory.com">pdxHistory.com</a>
            </p>

            <p>
                My project blog is: <a rel="noreferrer" target="_blank" href="http://www.pdxicon.com">pdxIcon.com</a>.
            </p> 
            <p>
                Wall Panels: <a href={`${path}artist-statement.pdf`} rel="noreferrer" target="_blank">Artist's Statement</a>,
                <a href={`${path}panel-1.pdf`} rel="noreferrer" target="_blank">Dairy in Oregon</a>, 
                <a href={`${path}panel-2.pdf`} rel="noreferrer" target="_blank">Dairy Cooperative Association</a>
            </p>
            <p>
                The links at left display individual paintings hanging in the exhibit, and reception photos.
            </p>
            {!props.error && (props.loading ? <div className="loadingContainer"><Spinner/></div> :
            <PaintingGallery nosale={true} collection={props.collection}/>
            )}

        </OHSContainer>
    );
}

export default OHS;
