import React, {useState} from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { FaAngleDown } from 'react-icons/fa';

const NavAccordion = styled.li`

    margin-bottom: .5rem;
    
    .accordion-line{
        padding-left: 1.25rem;
        &:hover{
            .accordion-link{
                color: var(--color-link-hover);
            }
        }
        .accordion-link{
            display: inline-block;
            width: 8rem;
            padding-bottom: .25rem;
            border-bottom: 1px solid black;

        }
        .accordion-icon{
            display: inline;
            width: 1.25rem;
            height: 1.25rem;
            transition: transform 0.3s ease-in-out;
            background-size: contain;
            background-repeat: no-repeat;
            float: right;
            transform: translateY(.25rem);

        }
        .accordion-icon--opened{
            transform: rotate(180deg);
        }

    }
    .accordion-item{
        display: flex;
        flex-direction: column;

        margin: 0;
        padding: 0;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
        transition-duration: 0.5s;
        transition-property: max-height;
        z-index: 1;
        position: relative;


        li{
            opacity: 0;
            transform: translateY(-1rem);
            transition-timing-function: linear, ease;
            transition-duration: 0.1s;
            transition-property: opacity, transform;
            transition-delay: 0.3s;
            
            a{
                
            }
        }
    }

    .accordion-item--opened{    
        opacity: 1;
        margin: 0;

        max-height: 33vh;
        overflow:none;
        overflow-y: scroll;
        transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
        transition-duration: 0.5s;
        transition-property: max-height;
        ::-webkit-scrollbar {
            background-color:transparent;
            width:0;
        }
        
        li{
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0.2s;
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
            transition-property: opacity, transform;
            a{

            }
        }
    }      

    @supports (display: grid){
        .accordion-line{
            display: grid;
            grid-template-columns: 1fr auto auto 1fr;

            .accordion-link{
                grid-column: 2/3;
            }
            .accordion-icon{
                grid-column: 3/4;
            }
        }
    }

`;


function NavlinkAccordion(props) {

    const [open, setOpen] = useState(false);
    function toggleOpen(){
        setOpen(!open);
    }

    return (
        <NavAccordion>
            <div className="accordion-line" onClick={toggleOpen}>
                <div className="accordion-link" >{props.text}</div>
                <FaAngleDown {...{className: `accordion-icon ${open && 'accordion-icon--opened'}`}}/>
            </div>
            <ul {...{className: `accordion-item ${open && 'accordion-item--opened'}`}}>
                {props.content && Object.entries(props.content).map(([key, item]) => (
                <li key={`${key}`}>
                    <NavLink to={`${props.path}${item.path}`} onClick={props.close}> 
                        {item.title} 
                    </NavLink>
                </li>
                ))}
            </ul>
            
        </NavAccordion>
    );
}

export default NavlinkAccordion;
